/*
	Is something an object?
	We want to know if something is one of these:
	{ cool: 'cool', awesome: 'awesome' }
*/
/**
 *
 * @param {*} potentialObject - The thing you want to test to see if it's an object
 * @returns True if the potentialObject has the typeof 'object' and it's not an array and it's not null
 */
const isObject = (potentialObject) => {
	return (
		typeof potentialObject === 'object' &&
		!Array.isArray(potentialObject) &&
		potentialObject !== null
	);
};

export default isObject;
