import React from 'react';
import { useFlags } from 'wsm-common-data';
import PropTypes from 'prop-types';
import vehicleDataUpdated from '../utils/vehicleDataUpdated';

let loaded = false;

export default function LegacyTracking({ legacyConfigurations }) {
	const flags = useFlags();
	async function loadFiles() {
		const { default: runTrackingFramework } = await import(
			'../utils/legacy/framework/trackingFramework'
		);
		runTrackingFramework(window, flags);

		const { default: initProject } = await import(
			'../utils/legacy/projects/projects'
		);

		if (
			window.DDC?.dataLayer?.page?.pageInfo?.isVlp &&
			!window.DDC?.dataLayer?.vehicles?.length
		) {
			await vehicleDataUpdated(window);
		}

		const initiatingProjects = legacyConfigurations.map((configuration) =>
			initProject(window, flags, configuration)
		);

		const projects = await Promise.all(initiatingProjects);

		// All projects have the same listener
		// RuntimeBuilder.js is designed to share the listener between all instances of itself
		// So we only need to manually initialize using the listener from one project
		projects?.[0]?.listener?.manuallyInitialize?.(); // not expecting this to fail but just in case
	}

	if (typeof window !== 'undefined' && !loaded) {
		loaded = true;

		// US814620 - This resolves a timing issue for shift legacy tracking
		// This below code will capture calls to window.sd for later use by the sd.js when it is loaded
		// Originally implemented to solve tracking issues for TradePending which loads faster than the sd.js
		if (legacyConfigurations.find((config) => config.project === 'shift')) {
			window.sd =
				window.sd ||
				function sd(...args) {
					(window.sd.q = window.sd.q || []).push(args);
				};
		}

		loadFiles();
	}

	return <span data-testid="LegacyTracking" />; // placholder is so we can grab this during unit testing
}

LegacyTracking.propTypes = {
	legacyConfigurations: PropTypes.arrayOf(PropTypes.object)
};
