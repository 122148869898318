import { SET_WIDGET_INFO } from '../actions/types';

export default function widgetInfo(state = {}, action) {
	if (!action.error && action.payload) {
		if (action?.type === SET_WIDGET_INFO) {
			return action.payload;
		}
	}

	return state;
}
