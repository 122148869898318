import { SET_LEGACY_CONFIGURATIONS } from '../actions/types';

export default function legacyConfigurations(state = [], action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_LEGACY_CONFIGURATIONS:
				return action.payload;

			// no default
		}
	}

	return state;
}
