import { addNewRelicPageAction } from 'ddc-new-relic';

/**
 *
 * @param {object} win - The window object
 * @returns A promise that will resolve once vehicle data is available in window.DDC.dataLayer.vehicles
 */
const vehicleHasLengthPromise = (dataLayer) =>
	new Promise((resolve) => {
		const interval = setInterval(() => {
			if (dataLayer?.vehicles?.length) {
				clearInterval(interval);
				resolve('datalayer');
			}
		}, 10);
	});

/**
 *
 * @param {Object} APILoader window.DDC.APILoader
 * @returns A promise which resolves when the WIAPI/DDC JS API says that vehicle data has been updated.
 */
const vehicleAPIPromise = async (APILoader) => {
	const API = await APILoader?.create('ws-tagging');
	return new Promise((resolve) => {
		API.subscribe('vehicle-data-updated-v1', () => {
			resolve('api');
		});
	});
};

/**
 *
 * @param {Integer} timeout A timeout indicating how long to wait until this function resolves
 * @returns A promise which resolves after the specified `timeout`
 */
const safeTimeoutPromise = (timeout) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve('timeout');
		}, timeout);
	});

/**
 *
 * @param {Object} window The global window object which needs should have DDC.dataLayer.[page|vehicles] and DDC.APILoader
 * @param {Integer} timeout A timeout indicating the maximum time in ms to wait for this function to resolve. Defaults to 5000 (5 seconds)
 * @returns A promise which resolves after the `timeout` or when window.DDC.dataLayer.vehicles length is over 0.
 * If the promise resolves with a 'value' of 'timeout' the vehicle data did not come in time.
 */
const vehicleDataUpdated = (
	{
		DDC: {
			dataLayer: { page = {}, vehicles = [] } = {},
			dataLayer = {},
			APILoader = {}
		} = {}
	} = {},
	timeout = 5000
) => {
	const vehiclesInfo = {
		isVlp: page?.pageInfo?.isVlp,
		vehiclesLength: vehicles?.length
	};

	let promise;
	if (vehiclesInfo.isVlp && !vehiclesInfo.vehiclesLength) {
		promise = new Promise((resolve) => {
			Promise.race([
				vehicleHasLengthPromise(dataLayer),
				vehicleAPIPromise(APILoader),
				safeTimeoutPromise(timeout)
			]).then((value) => {
				if (value === 'timeout') {
					addNewRelicPageAction('wsTaggingVehicleData', {
						wsTaggingError: 'vehicleDataDidntUpdate'
					});
				}
				resolve(value);
			});
		});
	} else {
		promise = Promise.resolve(vehiclesInfo);
	}

	return promise;
};

export default vehicleDataUpdated;
