import React, { useState, useEffect } from 'react';
import { useFlags } from 'wsm-common-data';
import PropTypes from 'prop-types';
import mergeObjects from '../utils/mergeObjects';

const catchHandler = (error) => {
	if (error.code !== 'MODULE_NOT_FOUND') {
		throw error;
	}
};

/**
 * This function takes object of global events and page specific events and merges them.
 * @param {Object} global
 * @param {Object} pageSpecific
 * @returns Merged events from global and pageSpecific.
 */
const mergePageSpecificMappings = (
	global = {
		events: {},
		fireGroups: {},
		triggerEvents: {}
	},
	pageSpecific = {
		events: {},
		fireGroups: {},
		triggerEvents: {}
	}
) => {
	const merged = {
		events: {}
	};

	if (pageSpecific?.events) {
		const { events } = global;

		// add empty event arrays for pageSpecific events so all the possible events are looped through
		Object.keys(pageSpecific?.events).forEach((event) => {
			if (!events[event]) {
				events[event] = [];
			}
		});

		// loop through events
		Object.entries(events).forEach(([event, rules]) => {
			// rules can be 1 object or array of objects so convert
			const globalRules = Array.isArray(rules) ? rules : [rules];

			// rules to unshift
			const rulesToUnshift = [];

			// rules to push
			const rulesToPush = [];

			if (pageSpecific?.events?.[event]) {
				// get page specific rules
				// rules can be 1 object or array of objects so convert
				const pageSpecificRules = Array.isArray(
					pageSpecific.events[event]
				)
					? pageSpecific.events[event]
					: [pageSpecific.events[event]];

				// loop through page specific rules
				pageSpecificRules.forEach((pageSpecificRule) => {
					const {
						insertion: _insertion,
						...pageSpecificRuleWithoutInsertion
					} = pageSpecificRule;
					if (pageSpecificRule?.insertion === 'unshift') {
						rulesToUnshift.push(pageSpecificRuleWithoutInsertion);
					} else {
						rulesToPush.push(pageSpecificRuleWithoutInsertion);
					}
				});
			}

			merged.events[event] = [
				...rulesToUnshift,
				...globalRules,
				...rulesToPush
			];
		});

		merged.fireGroups = {
			...global.fireGroups,
			...pageSpecific.fireGroups
		};
		merged.triggerEvents = {
			...global.triggerEvents,
			...pageSpecific.triggerEvents
		};
	} else {
		merged.events = {
			...global.events
		};
		merged.fireGroups = {
			...global.fireGroups
		};
		merged.triggerEvents = {
			...global.triggerEvents
		};
	}

	return merged;
};

/**
 * This function loads window.DDC.WidgetData['ws-tagging'].mapping with all the projects events and utils for the site.
 * grouping events and utils are merged into the default events and utils
 * if this turns out to be too heavy all we really need is:
 *    [project].event.events updated with {...defaultEventMapping, ...groupingEventMapping}
 *    [project].utils updated with {...defaultUtils, ...groupingUtils}
 * @param {*} mapping
 */
export const loadMapping = async (mapping, pageAlias, pageType) => {
	const allPromises = [];

	Object.entries(mapping).forEach(([project, { grouping }]) => {
		allPromises.push(
			Promise.all([
				project,
				import(`../../src/mappings/${project}/event/default`).catch(
					catchHandler
				),
				import(`../../src/mappings/${project}/event/${grouping}`).catch(
					catchHandler
				),
				import(
					`../../src/mappings/${project}/event/default/${pageAlias}`
				).catch(catchHandler),
				import(
					`../../src/mappings/${project}/event/${grouping}/${pageAlias}`
				).catch(catchHandler),
				import(
					`../../src/mappings/${project}/event/default/${pageType}`
				).catch(catchHandler),
				import(
					`../../src/mappings/${project}/event/${grouping}/${pageType}`
				).catch(catchHandler),
				import(`../../src/utils/${project}/utils`).catch(catchHandler),
				import(`../../src/utils/${project}/${grouping}/utils`).catch(
					catchHandler
				)
			]).then((projectMapping) => projectMapping)
		);
	});

	(await Promise.all(allPromises)).forEach(
		([
			project,
			{ default: defaultEventMapping = {} } = {},
			{ default: groupingEventMapping = {} } = {},
			{ default: pageEventMapping = {} } = {},
			{ default: groupingPageEventMapping = {} } = {},
			{ default: pageTypeEventMapping = {} } = {},
			{ default: groupingPageTypeEventMapping = {} } = {},
			defaultUtils = {},
			groupingUtils = {}
		]) => {
			// merge default and grouping mappings if grouping is not default
			let mergedEventMapping =
				defaultEventMapping === groupingEventMapping
					? defaultEventMapping
					: mergeObjects(defaultEventMapping, groupingEventMapping);

			// merge in page specific mappings
			mergedEventMapping = mergePageSpecificMappings(
				mergedEventMapping,
				pageEventMapping
			);

			// merge in page type specific mappings
			mergedEventMapping = mergePageSpecificMappings(
				mergedEventMapping,
				pageTypeEventMapping
			);

			// merge in group page specific mappings if grouping is not default
			mergedEventMapping =
				pageEventMapping === groupingPageEventMapping
					? mergedEventMapping
					: mergePageSpecificMappings(
							mergedEventMapping,
							groupingPageEventMapping
					  );

			// merge in grouping page type specific mappings if grouping is not default
			mergedEventMapping =
				defaultEventMapping === groupingEventMapping
					? mergedEventMapping
					: mergePageSpecificMappings(
							mergedEventMapping,
							groupingPageTypeEventMapping
					  );

			window.DDC.WidgetData['ws-tagging'].mapping[project].event =
				mergedEventMapping;
			window.DDC.WidgetData['ws-tagging'].mapping[project].utils =
				mergeObjects(defaultUtils, groupingUtils);
		}
	);
};

export default function DataMapper({
	mapping = {},
	pageAlias = '',
	pageType = ''
}) {
	const flags = useFlags();
	const [appIsMounted, setAppIsMounted] = useState(null);

	useEffect(() => {
		(async () => {
			await loadMapping(mapping, pageAlias, pageType);

			const { initIfWsTaggingReady } = await import(
				'../utils/dataMapper'
			);
			initIfWsTaggingReady(flags);
			setAppIsMounted(true);
		})();
	}, [flags, mapping, pageAlias, pageType]);

	return appIsMounted ? <span data-testid="DataMapper" /> : null; // placholder is so we can grab this during unit testing
}

DataMapper.propTypes = {
	mapping: PropTypes.objectOf(PropTypes.object),
	pageAlias: PropTypes.string,
	pageType: PropTypes.string
};
