import isObject from './isObject';

/**
 * Recursively merges two objects, where properties from `obj2` overwrite properties in `obj1`
 * if they have the same key.
 *
 * @param {Object} obj1 - The first object to merge.
 * @param {Object} obj2 - The second object whose properties will be merged into `obj1`.
 * @param {Boolean} clone - clone obj1 and obj2 before merging so you get a completely new object back
 * @returns {Object} - The merged object.
 */
const mergeObjects = (obj1, obj2, clone) => {
	const merged = clone ? JSON.parse(JSON.stringify(obj1)) : { ...obj1 };
	const object2 = clone ? JSON.parse(JSON.stringify(obj2)) : obj2;

	// Iterate over keys in obj2
	Object.keys(object2).forEach((key) => {
		// If object2's key is an object and also exists in merged, recursively merge them
		if (isObject(object2[key]) && isObject(merged?.[key])) {
			merged[key] = mergeObjects(merged[key], object2[key], clone);
		} else {
			// Otherwise, assign object2's value to the merged object
			merged[key] = object2[key];
		}
	});

	return merged;
};

export default mergeObjects;
