import getEnv from './getEnv';

let sdLoaded = false;

const loadShift = (legacyConfigurations) => {
	// window check because this tries to render on the server
	// sdLoaded check because render runs twice
	if (typeof window !== 'undefined' && !sdLoaded) {
		const data = {};
		if (legacyConfigurations) {
			legacyConfigurations.forEach((legacyConfiguration) => {
				if (legacyConfiguration.project === 'shift') {
					data.scriptLocation =
						getEnv() === 'prod'
							? legacyConfiguration.liveScriptLocation
							: legacyConfiguration.defaultScriptLocation;
					data.scriptObjectName =
						legacyConfiguration.scriptObjectName;
					data.clientId = legacyConfiguration.clientId;
				}
			});
		}

		if (Object.keys(data).length > 0) {
			/* eslint-disable func-names,no-param-reassign,no-unused-expressions,prefer-rest-params,no-sequences,prefer-destructuring */
			(function (s, h, i, f, t, d, g, l) {
				(s[d] =
					s[d] ||
					function () {
						(s[d].q = s[d].q || []).push(arguments);
					}),
					(s[d].l = 1 * new Date());
				(g = h.createElement(i)), (l = h.getElementsByTagName(i)[0]);
				g.async = 1;
				g.src = `${f}?containerId=${t}&dl=${d}`;
				l.parentNode.insertBefore(g, l);
			})(
				window,
				document,
				'script',
				data.scriptLocation,
				data.clientId,
				data.scriptObjectName
			);

			sdLoaded = true;
		}
	}
};

export default loadShift;
