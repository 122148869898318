import legacyConfigurations from './legacyConfigurations';
import mapping from './mapping';
import widgetInfo from './widgetInfo';

const root = {
	legacyConfigurations,
	mapping,
	widgetInfo
};

export default root;
