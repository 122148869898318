export const NONPROD = 'nonprod';
export const PROD = 'prod';
export const LOCAL = 'local';

/**
 * @param {Object} configuration Optionally pass in the host and whether to return if in the local env
 * @returns 'nonprod' if on local or nonprod and 'prod' if on a live site
 * @description We can easily update this function in the future if someone tells us
 * "DO IT THIS OTHER AWESOME WAY INSTEAD!!!"
 * @todo Be nice to simplify this function to not have the returnLocal configuration and update all calls
 *  to getEnv to work correctly based on what env is returned.
 *  ex. getEnv() !== PROD instead of getEnv() === NONPROD
 */
const getEnv = ({ host, returnLocal } = {}) => {
	let env = NONPROD;
	let testForEnv;

	// ultra safe just in case window.location not defined we do not blow up
	try {
		testForEnv = host || window?.location?.hostname;
	} catch {
		return env;
	}

	if (testForEnv) {
		if (returnLocal && testForEnv.includes('.localhost.')) {
			env = LOCAL;
		} else if (
			!testForEnv.includes('.web-np.') &&
			!testForEnv.includes('.localhost.')
		) {
			env = PROD;
		}
	}

	return env;
};

export default getEnv;
